import React from 'react'
import { Link } from 'gatsby'
import * as postingStyles from './PostsListing.module.scss'

const MindmapListing = ({ postEdges }) => {
  const getPostList = () => {
    const postList = []
    postEdges.forEach(postEdge => {
      if(postEdge.node.frontmatter.type == 'mindmap')
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        categories: postEdge.node.frontmatter.categories,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        
      })
    })
    return postList
  }

  const postList = getPostList()
  return (
    <div className={postingStyles.articleList}>
      {/* Your post list here. */
      postList.map(post => (
        <Link to={post.path} key={post.title}>
          <article className={postingStyles.articleBox}>
            <div className={postingStyles.right}>
              <h3>{post.title}</h3>
              
             
              
            </div>
          </article>
        </Link>
      ))}
    </div>
  )
}

export default MindmapListing
